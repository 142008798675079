import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	pageTitle: {
		id: 'home.document-title.page-title',
		defaultMessage: 'Your work - {appTitle}',
		description:
			'The title for the Jira home page. The appTitle placeholder is the name of the Jira instance.',
	},
	pageTitleYourWork: {
		id: 'home.document-title.page-title-your-work',
		defaultMessage: 'Your work',
		description: 'The title for the Jira home page.',
	},
});
