// eslint-disable-next-line jira/restricted/react-component-props
import React, { type ComponentProps } from 'react';
import { styled as compiledStyled } from '@compiled/react';
import ShipIcon from '@atlaskit/icon/glyph/ship';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import AsyncIcon from '@atlassian/jira-common-components-async-icon';
import { borderRadius } from '@atlassian/jira-common-styles/src';

const HeaderIcon = (
	props: JSX.LibraryManagedAttributes<typeof AsyncIcon, ComponentProps<typeof AsyncIcon>>,
) => (
	<StyledContainer>
		<AsyncIcon
			fallback={() => (
				<BackgroundContainer>
					<ShipIcon size="medium" label="" primaryColor={token('color.icon.inverse', colors.N0)} />
				</BackgroundContainer>
			)}
			{...props}
		/>
	</StyledContainer>
);

export default HeaderIcon;

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BackgroundContainer = compiledStyled.span<any>({
	lineHeight: 1,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> span': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		background: token('color.background.neutral.bold', colors.N50),

		borderRadius: '2px',
	},
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledContainer = compiledStyled.span<any>({
	display: 'inline-flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> img': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		borderRadius: `${borderRadius}px`,
	},
});
