import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	today: {
		defaultMessage: 'Today',
		id: 'home.common.today',
		description: 'The label for the Jira Home recently viewed tab sub header.',
	},
	yesterday: {
		defaultMessage: 'Yesterday',
		id: 'home.common.yesterday',
		description: 'The label for the Jira Home recently viewed tab sub header.',
	},
	inTheLastWeek: {
		defaultMessage: 'In the last week',
		id: 'home.common.in-the-last-week',
		description: 'The label for the Jira Home recently viewed tab sub header.',
	},
	inTheLastMonth: {
		defaultMessage: 'In the last month',
		id: 'home.common.in-the-last-month',
		description: 'The label for the Jira Home recently viewed tab sub header.',
	},
	moreThanAMonthAgo: {
		defaultMessage: 'More than a month ago',
		id: 'home.common.more-than-a-month-ago',
		description: 'The label for the Jira Home recently viewed tab sub header.',
	},
	assignedAction: {
		defaultMessage: 'Assigned',
		id: 'home.common.assigned-action',
		description: 'The label for Assigned action.',
	},
	commentedAction: {
		defaultMessage: 'Commented on',
		id: 'home.common.commented-action',
		description: 'The label for Commented on action.',
	},
	createdAction: {
		defaultMessage: 'Created',
		id: 'home.common.created-action',
		description: 'The label for Created action.',
	},
	editedAction: {
		defaultMessage: 'Edited',
		id: 'home.common.edited-action',
		description: 'The label for Edited action.',
	},
	linkedAction: {
		defaultMessage: 'Liked',
		id: 'home.common.linked-action',
		description: 'The label for Liked action.',
	},
	publishedAction: {
		defaultMessage: 'Published',
		id: 'home.common.published-action',
		description: 'The label for Published action.',
	},
	transitionedAction: {
		defaultMessage: 'Transitioned',
		id: 'home.common.transitioned-action',
		description: 'The label for Transitioned action.',
	},
	unassignedAction: {
		defaultMessage: 'Unassigned',
		id: 'home.common.unassigned-action',
		description: 'The label for Unassigned action.',
	},
	updatedAction: {
		defaultMessage: 'Updated',
		id: 'home.common.updated-action',
		description: 'The label for Updated action.',
	},
	viewedAction: {
		defaultMessage: 'Viewed',
		id: 'home.common.viewed-action',
		description: 'The label for Viewed action.',
	},
});
