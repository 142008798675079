/* eslint-disable jira/integration/enforce-data-testid-usage */
import React, {
	useCallback,
	type ReactNode,
	type ComponentPropsWithRef,
	type RefAttributes,
} from 'react';
import { styled as styled2 } from '@compiled/react';
// eslint-disable-next-line jira/restricted/styled-components-migration, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled, { type StyledComponentClass } from 'styled-components';
import AvatarGroupComponent from '@atlaskit/avatar-group';
import { colors, typography, fontSizeSmall } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import FavoriteButtonStateless from '@atlassian/jira-favourite-button-stateless/src';
import FavoriteButton from '@atlassian/jira-favourite-button/src';
import type { ItemType as FavouriteItemType } from '@atlassian/jira-favourite-change-provider/src';
import { type IntlShape, useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
// import type { Link } from '@atlassian/jira-router';
import { Link } from '@atlassian/jira-router';
import { getPracticeLabel } from '@atlassian/jira-servicedesk-work-category';
import {
	BOARDS_ITEM_TYPE,
	FILTERS_ITEM_TYPE,
	DASHBOARDS_ITEM_TYPE,
	ISSUES_ITEM_TYPE,
	COMMENT_ITEM_TYPE,
	PROJECTS_ITEM_TYPE,
	QUEUES_ITEM_TYPE,
	PLANS_ITEM_TYPE,
} from '../../../constants/item-types';
import type { Item as ItemData } from '../../../types/item';
import ItemIcon from './item-icon';
import messages from './messages';
import { convertToFavouriteItemType, getProjectType } from './utils';
import { WithCategory } from './with-category';

export type Props = {
	item: ItemData;
	index: number;
	showStar?: boolean;
	elemBefore?: ReactNode;
	onItemClick?: () => void;
};

const middot = String.fromCharCode(183);

type FavoriteButtonProps = {
	isFavoriteInitial: boolean;
	itemId: string;
	itemType: FavouriteItemType;
};

const FavoriteButtonSSR = ({ itemId, itemType, isFavoriteInitial }: FavoriteButtonProps) =>
	__SERVER__ ? (
		<FavoriteButtonStateless isFavorite={isFavoriteInitial} isSmall />
	) : (
		<FavoriteButton
			itemId={itemId}
			itemType={itemType}
			baseUrl=""
			isFavoriteInitial={isFavoriteInitial}
			isSmall
		/>
	);

const getAdditionalAttributes = (item: ItemData, type: string, intl: IntlShape) => {
	const { formatMessage } = intl;

	let location;
	let category;
	let { subtitle } = item;

	switch (type) {
		case BOARDS_ITEM_TYPE:
			subtitle = formatMessage(messages.board);
			location = undefined;
			if (
				typeof item.attributes !== 'undefined' &&
				typeof item.attributes.containerName === 'string'
			) {
				location = item.attributes.containerName;
			}
			break;
		case QUEUES_ITEM_TYPE:
			subtitle = formatMessage(messages.queue);
			location = '';
			if (typeof item.metadata === 'string') {
				location = item.metadata;
			}
			{
				const { attributes = {} } = item;
				const { queuesCategory } = attributes;
				const practiceLabel = queuesCategory != null ? getPracticeLabel(queuesCategory) : undefined;
				category = practiceLabel && formatMessage(practiceLabel);
			}
			break;
		case FILTERS_ITEM_TYPE:
			subtitle = formatMessage(messages.filter);

			break;
		case DASHBOARDS_ITEM_TYPE:
			subtitle = formatMessage(messages.dashboard);

			break;
		case ISSUES_ITEM_TYPE:
		case COMMENT_ITEM_TYPE:
			if (
				typeof item.attributes !== 'undefined' &&
				typeof item.attributes.containerName === 'string'
			) {
				location = item.attributes.containerName;
			}
			break;
		case PROJECTS_ITEM_TYPE:
			subtitle = getProjectType(item, intl);
			break;
		case PLANS_ITEM_TYPE:
			subtitle = formatMessage(messages.plan);
			break;
		default:
			subtitle = undefined;
			break;
	}

	if (typeof subtitle === 'string' && typeof location === 'string') {
		// @ts-expect-error - TS2322 - Type 'Element' is not assignable to type 'string'.
		subtitle = (
			<>
				<span>{subtitle}</span>
				<span>{middot}</span>
				<span>{location}</span>
			</>
		);
	} else if (typeof location === 'string') {
		subtitle = location;
	}

	return { category, subtitle };
};

const Item = ({ item, showStar, elemBefore, index, onItemClick }: Props) => {
	const intl = useIntl();

	// conform to lowercase, and remove the pluralised `s` if it exists
	const type = item.type.toLowerCase().replace(/s\s*$/, '');
	// for the favourite button we also need to ensure we pass the pluralised type
	const pluralisedType: FavouriteItemType = convertToFavouriteItemType(type);

	const { category, subtitle } = getAdditionalAttributes(item, type, intl);

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onClick = useCallback(() => {
		const analyticsEvent = createAnalyticsEvent({
			action: 'clicked',
			actionSubject: 'link',
		});

		fireUIAnalytics(analyticsEvent, 'jiraHomeLink', {
			itemType: item.type,
			itemIndex: index,
			itemAction: item.action,
		});

		onItemClick && onItemClick();
	}, [createAnalyticsEvent, item.type, item.action, index, onItemClick]);

	return (
		<ItemLink
			href={item.url}
			key={item.url}
			onClick={onClick}
			data-test-id={`global-pages.home.ui.tab-container.tab.item-list.item-link#${
				item.type ? item.type.toString() : ''
			}-${item.id}`}
		>
			{showStar && item.entityId && typeof item.favourite !== 'undefined' && (
				<AfterIconsContainer>
					{elemBefore}
					<FavoriteButtonSSR
						itemId={item.entityId}
						itemType={pluralisedType}
						isFavoriteInitial={item.favourite}
					/>
				</AfterIconsContainer>
			)}
			<ItemIcon type={type} url={item.avatarUrl} />
			<InnerWrapper>
				<Title>
					<WithCategory category={category}>{item.title}</WithCategory>
				</Title>
				<Subtitle>{subtitle}</Subtitle>
			</InnerWrapper>
			{item.status && <Status>{item.status}</Status>}
			{item.action && <Action>{item.action}</Action>}
			<AvatarGroupWrapper>
				{item.contributors && (
					<AvatarGroup
						data={item.contributors.map((contributor) => ({
							name: contributor.name,
							src: contributor.picture && contributor.picture,
							appearance: 'circle',
							size: 'medium',
							enableTooltip: true,
						}))}
					/>
				)}
			</AvatarGroupWrapper>
		</ItemLink>
	);
};
Item.whyDidYouRender = true;

export default Item;

// type LinkProps = ComponentPropsWithRef<typeof Link>;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InnerWrapper = styled2.span({
	flex: 6,
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'flex-start',
	margin: `0 ${token('space.100', '8px')}`,
});

// TODO: migrate to object syntax. Autofix is available for many cases. Remove the eslint-disable for @atlaskit/design-system/no-styled-tagged-template-expression to check.
// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Title = styled.h4`
	${typography.h400};
	flex-grow: 1;
	font-size: 14px;
	line-height: 20px;
	font-weight: normal !important;
	margin: 0;
`;

// TODO: migrate to object syntax. Autofix is available for many cases. Remove the eslint-disable for @atlaskit/design-system/no-styled-tagged-template-expression to check.
// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Subtitle = styled.small`
	flex-grow: 1;
	margin: 0;
	font-size: ${fontSizeSmall}px;
	color: ${token('color.text.subtlest', colors.N200)};
	line-height: 14px;

	> span:not(:first-child):not(:last-child) {
		margin: 0 ${token('space.100', '8px')};
	}
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Status = styled.span({
	flex: 1,
	margin: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', colors.N200),
	verticalAlign: 'center',
	minWidth: '8em',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	fontSize: `${fontSizeSmall}px`,
	paddingRight: token('space.200', '16px'),
	textAlign: 'right',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Action = styled.span({
	flex: 1,
	margin: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', colors.N200),
	verticalAlign: 'middle',
	minWidth: '8em',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	fontSize: `${fontSizeSmall}px`,
	paddingRight: token('space.200', '16px'),
	textAlign: 'right',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AvatarGroupWrapper = styled.div({
	margin: 0,
	display: 'flex',
	flexGrow: 0,
	flexShrink: 0,
	justifyContent: 'flex-end',
	width: 'auto',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AfterIconsContainer = styled2.div({
	display: 'flex',
	alignItems: 'center',
	marginLeft: token('space.negative.150', '-12px'),
	marginRight: token('space.100', '8px'),
});

type LinkProps = ComponentPropsWithRef<typeof Link>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any,  @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ItemLink: StyledComponentClass<LinkProps & RefAttributes<any>, any> = styled(Link)({
	marginTop: 0,
	marginLeft: token('space.negative.100', '-8px'),
	marginRight: token('space.negative.100', '-8px'),
	flex: 1,
	padding: token('space.100', '8px'),
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'flex-start',
	alignItems: 'center',
	borderRadius: '3px',
	textDecoration: 'none',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.link', colors.N800),
	'&:hover, &:focus': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		color: `${token('color.link', colors.B400)} !important`,
		backgroundColor: token('color.background.neutral.subtle.hovered', 'rgba(9, 30, 66, 0.04)'),
		textDecoration: 'none',
	},
	'&:active': {
		backgroundColor: token('color.background.neutral.subtle.pressed', 'rgba(222, 235, 255, 1)'),
		fill: 'rgba(222, 235, 255, 1)',
		color: 'rgba(0, 82, 204, 1)',
	},
});

// eslint-disable-next-line @atlaskit/design-system/no-unsafe-style-overrides, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AvatarGroup = styled(AvatarGroupComponent)({
	justifyContent: 'flex-end',
	width: '200px',
});
