// eslint-disable-next-line jira/wrm/no-load-bridge
import { loadBridge } from '@atlassian/jira-common-bridge/src';

const PUSH_STATE_NAVIGATOR_MODULE =
	'servicedesk/internal-api/core/util/push-state-navigator/push-state-navigator';

// Try to require push-state-navigator over 500ms, otherwise we'll fallback.
const REQUIRE_TIMEOUT = 100;
const REQUIRE_RETRIES = 5;

export type PushStateNavigator = {
	navigateToFragment: (arg1: string) => void;
	navigate: (arg1: string, arg2: boolean) => void;
	replaceFragment: (arg1: string) => void;
	refresh: () => void;
	hasAnyLinkBeenHijacked: () => boolean;
};

export const createPushStateNavigator = (): Promise<PushStateNavigator> =>
	// eslint-disable-next-line jira/wrm/no-load-bridge
	loadBridge({
		name: PUSH_STATE_NAVIGATOR_MODULE,
		timeout: REQUIRE_TIMEOUT,
		tentatives: REQUIRE_RETRIES,
	});
