import { useEffect } from 'react';
import isEqualWith from 'lodash/isEqualWith';
import { parseISO, startOfDay, subDays, isAfter, isBefore } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import sendExperienceAnalytics from '@atlassian/jira-common-experience-tracking-analytics';
import { fireErrorAnalytics } from '@atlassian/jira-errors-handling';
import type { IntlShape } from '@atlassian/jira-intl';
import type { TimeZone } from '@atlassian/jira-shared-types';
import type { TabsTypes, SUCCESS_STATE, ERROR_STATE, EMPTY_STATE } from './constants/tab-content';
import type { Item } from './types/item';
import type { ItemProviderDataSection, ItemProviderData } from './types/item-provider';
import { interactionMetrics } from './utils/performance-analytics';

type SortedItems = {
	todayItems: Item[];
	yesterdayItems: Item[];
	thisWeekItems: Item[];
	thisMonthItems: Item[];
	moreThanAMonthItems: Item[];
};

export const getSortedItems = (
	result: Item[],
	timeZone: TimeZone,
	intl: IntlShape,
	getDateForComparison: (item: Item) => Date | undefined,
): SortedItems => {
	// items
	const todayItems: Array<Item> = [];
	const yesterdayItems: Array<Item> = [];
	const thisWeekItems: Array<Item> = [];
	const thisMonthItems: Array<Item> = [];
	const moreThanAMonthItems: Array<Item> = [];

	const currentTimeInTimeZone = utcToZonedTime(parseISO(new Date().toISOString()), timeZone);

	// epochs for comparison
	const today = startOfDay(currentTimeInTimeZone);
	const yesterday = startOfDay(subDays(currentTimeInTimeZone, 1));
	const oneWeekAgo = startOfDay(subDays(currentTimeInTimeZone, 6));
	const oneMonthAgo = startOfDay(subDays(currentTimeInTimeZone, 29));

	result.forEach((item: Item): void => {
		const since = getDateForComparison(item);

		if (since !== undefined) {
			if (isAfter(since, today)) {
				todayItems.push(item);
			} else if (isAfter(since, yesterday)) {
				yesterdayItems.push(item);
			} else if (isAfter(since, oneWeekAgo)) {
				thisWeekItems.push(item);
			} else if (isBefore(since, oneMonthAgo)) {
				moreThanAMonthItems.push(item);
			} else if (isAfter(since, oneMonthAgo)) {
				thisMonthItems.push(item);
			}
		}
	});

	return { todayItems, yesterdayItems, thisWeekItems, thisMonthItems, moreThanAMonthItems };
};

/**
 * Removes the items in the tail-most sections until the number of items in all the sections
 * is not more than `maxItems`.
 */
export const trimItemsBySection = (maxItems: number, sections: ItemProviderDataSection[]) =>
	sections.reduce<{ itemCount: number; sections: ItemProviderDataSection[] }>(
		(accumulator, currentSection) => {
			const { itemCount } = accumulator;

			if (itemCount < maxItems) {
				const additionalItemCount = Math.min(currentSection.items.length, maxItems - itemCount);
				accumulator.sections.push({
					...currentSection,
					items: currentSection.items.slice(0, additionalItemCount),
				});

				accumulator.itemCount += additionalItemCount;
			}
			return accumulator;
		},
		{ itemCount: 0, sections: [] },
	).sections;

export const addSection = (sections: ItemProviderDataSection[], items: Item[], header: string) => {
	if (items.length > 0) {
		sections.push({
			header,
			items,
		});
	}
};

export const isItemProviderDataEqual = (oldData: ItemProviderData, newData: ItemProviderData) =>
	// deep equal, ignoring viewTimeAgo attribute
	isEqualWith(oldData, newData, (val1, val2, key) => {
		if (key === 'viewTimeAgo') {
			return true;
		}
		return undefined;
	});

type EndInteractionsTrackerProps = {
	transitionKey: TabsTypes;
	renderState: typeof SUCCESS_STATE | typeof ERROR_STATE | typeof EMPTY_STATE;
};

export const EndInteractionsTracker = ({
	transitionKey,
	renderState,
}: EndInteractionsTrackerProps) => {
	useEffect(() => {
		interactionMetrics[transitionKey].stop({ customData: { renderState } });
	}, [transitionKey, renderState]);

	return null;
};

export const useFireErrorOrSuccessAnalytics = (
	hasData: boolean,
	error: Error | null | undefined,
	isLoading: boolean,
	tab: string,
	isUnauthenticated?: boolean,
) => {
	const wasExperienceSuccessful = hasData || !error;
	useEffect(() => {
		if (!isLoading && !(isUnauthenticated != null && isUnauthenticated === true)) {
			sendExperienceAnalytics({
				analyticsSource: 'jiraHomeTabScreen',
				wasExperienceSuccesful: wasExperienceSuccessful,
				experience: 'showTabData',
				application: null,
				edition: null,
				additionalAttributes: {
					tab,
				},
			});
			if (!wasExperienceSuccessful) {
				fireErrorAnalytics({
					meta: {
						id: 'showTabData',
						packageName: 'jiraHome',
					},
					error,
					attributes: {
						tab,
					},
				});
			}
		}
		// We need to prevent the 'error' object being added into
		// the deps list to avoid additional analytics being fired.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading, isUnauthenticated, tab, wasExperienceSuccessful]);
};
