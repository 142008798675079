import { useEffect } from 'react';
import { useLazyLoadQuery } from 'react-relay';
import { useGetQueryById } from '../store';

export const Query = ({ id }: { id: string }) => {
	const [queryConfig, { setQueryData }] = useGetQueryById(id);

	if (queryConfig === undefined) {
		throw new Error(`Query config not found for id=${id}`);
	}

	const { query, variables, fetchKey, fetchPolicy } = queryConfig;

	/* eslint-disable @atlassian/relay/generated-typescript-types */
	/* eslint-disable @atlassian/relay/query-restriction */
	const data = useLazyLoadQuery(query, variables, { fetchKey, fetchPolicy });
	/* eslint-enable @atlassian/relay/generated-typescript-types */
	/* eslint-enable @atlassian/relay/query-restriction */

	useEffect(() => {
		setQueryData(id, data);
	}, [id, data, setQueryData]);

	return null;
};
