import React from 'react';
import { YourWorkItemListSkeleton } from '@atlassian/jira-skeletons';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller';
import {
	STARRED_TAB_KEY,
	SUCCESS_STATE,
	ERROR_STATE,
	EMPTY_STATE,
} from '../../../common/constants/tab-content';
import type { TabProps } from '../../../common/types/tabs';
import ErrorState from '../../../common/ui/error-state';
import ReloadingSpinner from '../../../common/ui/reloading-spinner';
import { EndInteractionsTracker, useFireErrorOrSuccessAnalytics } from '../../../common/utils';
import EmptyState from './empty-state';
import ItemList from './reorder-items';

const StarredTab = (props: TabProps) => {
	const {
		itemProviderResult: { data, error, loading, reloading, reorder },
	} = props;

	const { appPermissions } = useTenantContext();

	useFireErrorOrSuccessAnalytics(!!data.total, error, loading, 'starred');

	if (error && !data.total) {
		return (
			<>
				<ErrorState />
				<EndInteractionsTracker transitionKey={STARRED_TAB_KEY} renderState={ERROR_STATE} />
			</>
		);
	}

	if (loading) {
		return <YourWorkItemListSkeleton />;
	}

	if (!data || !data.sections || data.sections.length === 0) {
		return (
			<>
				<EmptyState
					shouldShowBoardsLink={appPermissions && appPermissions.hasSoftwareAccess}
					shouldShowServiceDeskLink={appPermissions && appPermissions.hasServiceDeskAccess}
					shouldShowPlansLink={appPermissions && appPermissions.hasSoftwareAccess}
				/>
				<EndInteractionsTracker transitionKey={STARRED_TAB_KEY} renderState={EMPTY_STATE} />
			</>
		);
	}

	return (
		<>
			{reloading && <ReloadingSpinner />}
			<ItemList sections={data.sections} maxItems={props.maxItems} onReorderItems={reorder} />
			<EndInteractionsTracker transitionKey={STARRED_TAB_KEY} renderState={SUCCESS_STATE} />
		</>
	);
};

export default StarredTab;
