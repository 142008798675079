import React from 'react';
import { styled } from '@compiled/react';
import isEmpty from 'lodash/isEmpty';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

type Props = {
	category?: string;
	children: string;
};

export const WithCategory = ({ category, children }: Props) => (
	<>
		{!isEmpty(category) ? <Category>{category} / </Category> : null}
		{children}
	</>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Category = styled.span({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtle', colors.N400),
});
