import { useEffect, useState } from 'react';
import { fireErrorAnalytics } from '@atlassian/jira-errors-handling';
import { fetchJson } from '@atlassian/jira-fetch';
import { getItsmSampleProject } from '@atlassian/jira-servicedesk-itsm-sample-project';
import { toBaseUrl, LICENSE_STATE_ACTIVE } from '@atlassian/jira-shared-types';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller';

/**
 * This is only a partially typed response. There are other returned properties that are
 * unused by us.
 */
type RecentsResponse = {
	id: string;
	properties: {
		isDemo?: true;
	};
}[];

export const useDemoProjectIds = () => {
	const [itsmSampleProjectId, setItsmSampleProjectId] = useState<number | undefined>(undefined);
	const [demoProjectId, setDemoProjectId] = useState<number | undefined>(undefined);
	const { baseUrl, licenseStates } = useTenantContext();

	useEffect(() => {
		const hasSDLicense = licenseStates.serviceDesk === LICENSE_STATE_ACTIVE;
		if (!hasSDLicense) {
			return;
		}

		(async () => {
			const [itsmSampleProject, recentProjectsResponse] = await Promise.all([
				getItsmSampleProject({ baseUrl: toBaseUrl(baseUrl) }),
				fetchJson<RecentsResponse>('/rest/api/2/project?recent=5&properties=isDemo'),
			]);

			const demoProjects = recentProjectsResponse.filter(
				(project) =>
					project.properties.isDemo && parseInt(project.id, 10) !== itsmSampleProject?.projectId,
			);

			// Manual assertion that logs to Sentry
			if (demoProjects.length > 1) {
				fireErrorAnalytics({
					error: new Error('Unexpected case of multiple recent demo projects'),
					meta: {
						id: 'demo-project',
						packageName: 'jiraDeepDiveCardsSection',
					},
				});
			}
			if (demoProjects.length >= 1) {
				setDemoProjectId(parseInt(demoProjects[0].id, 10));
			}
			if (itsmSampleProject) {
				setItsmSampleProjectId(itsmSampleProject.projectId);
			}
		})();
	}, [baseUrl, licenseStates.serviceDesk]);

	return { itsmSampleProjectId, demoProjectId };
};
