import React, { type ReactNode, Component } from 'react';
import isEqual from 'lodash/isEqual';
import type { OperationType } from 'relay-runtime';
import { useGetQueryById } from '../store';

type State = {
	error: Error | typeof undefined;
};

type Props = {
	children: ReactNode;
	variables: OperationType['variables'];
	fetchKey: string;
	onError: (error: Error) => void;
};

class RelayMigrationUtilsErrorBoundary extends Component<Props, State> {
	state = {
		error: undefined,
	};

	componentDidUpdate(prevProps: Props) {
		const { variables, fetchKey } = this.props;
		const { variables: prevVariables, fetchKey: prevFetchKey } = prevProps;

		// reset error state and give a chance query to recover after variables/fetchKey change
		if (!isEqual(variables, prevVariables) || fetchKey !== prevFetchKey) {
			this.setState({
				error: undefined,
			});
		}
	}

	componentDidCatch(error: Error) {
		this.setState({ error });
		this.props.onError(error);
	}

	render() {
		const { error } = this.state;
		if (error) return null;

		return this.props.children;
	}
}

export const ErrorBoundary = ({ id, children }: { id: string; children: ReactNode }) => {
	const [queryConfig, { setQueryError }] = useGetQueryById(id);

	// this case is not possible, this check is here just to make flow happy
	if (!queryConfig) throw new Error(`Query config not found for id=${id}`);

	const { variables, fetchKey } = queryConfig;

	return (
		<RelayMigrationUtilsErrorBoundary
			variables={variables}
			fetchKey={fetchKey}
			onError={(error) => setQueryError(id, error)}
		>
			{children}
		</RelayMigrationUtilsErrorBoundary>
	);
};
