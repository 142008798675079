// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useCallback, useEffect, useRef, Fragment } from 'react';
import '@atlaskit/css-reset';
import AppBase from '@atlassian/jira-app-base';
import AppStyle from '@atlassian/jira-common-components-app-style';
import LoginRedirect from '@atlassian/jira-common-navigation/src/login-redirect';
import log from '@atlassian/jira-common-util-logging/src/log';
import type { FetchTabDataFn } from '@atlassian/jira-home/src/common/types/fetch.tsx';
import {
	fetchData as assignedToUserFetchData,
	transformResult as transformAssignedToUserResult,
} from '@atlassian/jira-home/src/services/assigned-to-user-provider/index.tsx';
import {
	fetchData as favouritesFetchData,
	transformResult as transformFavouritesResult,
} from '@atlassian/jira-home/src/services/favourites-provider/index.tsx';
import { transformResult as transformRecentActivitiesResult } from '@atlassian/jira-home/src/services/recent-activity-provider/index.tsx';
import {
	fetchAllData as recentlyViewedFetchData,
	transformResult as transformRecentlyViewedResult,
} from '@atlassian/jira-home/src/services/recently-viewed-provider/index.tsx';
import { type IntlShape, useIntl } from '@atlassian/jira-intl';
import { withSuspense } from '@atlassian/jira-platform-resources-utils/src/utils/with-suspense/index.tsx';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useResource } from '@atlassian/jira-router';
import { jiraHomeResourceWithCache } from '@atlassian/jira-router-resources-jira-home/src';
import { OnboardingCheck } from '@atlassian/jira-spa-apps-common/src/interceptors/onboarding/index.tsx';
import JiraHome from '@atlassian/jira-spa-apps-jira-home/src/view/view.tsx';
import { MarkProductStart } from '@atlassian/jira-spa-performance-breakdown/src/utils/mark-product-start/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment';
import { generateUpdateTabStateFn } from './utils';

const useJiraHomePrefetchResource = withSuspense((intl: IntlShape) => {
	const assignedToUserLoading = useRef(false);
	const state = useResource(jiraHomeResourceWithCache);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const generateUpdateTabFn = useCallback(
		(tabName: string, fetchData: FetchTabDataFn) =>
			generateUpdateTabStateFn(state, tabName, fetchData),
		[state],
	);

	const updateLastVisitedTab = useCallback(
		(lastVisitedTab: string) => {
			state.update(
				(currentData) =>
					currentData && {
						...currentData,
						lastVisitedTab,
					},
			);
		},
		[state],
	);

	useEffect(() => {
		if (
			state?.data?.tabs.AssignedToUser?.data?.issues?.totalCount == null &&
			!assignedToUserLoading.current
		) {
			assignedToUserLoading.current = true;
			assignedToUserFetchData().then((assignedToUserResults) => {
				state.update(
					(currentData) =>
						currentData && {
							...currentData,
							tabs: {
								...currentData.tabs,
								AssignedToUser: assignedToUserResults,
							},
						},
				);
			});
		}
	}, [state]);

	useEffect(() => {
		if (state?.data?.analytics) {
			fireUIAnalytics(
				createAnalyticsEvent({ action: 'load', actionSubject: 'jiraHome' }),
				'jiraHomeResource',
				state.data.analytics,
			);
		}
	}, [createAnalyticsEvent, state?.data?.analytics]);

	try {
		if (state.data) {
			const { RecentlyViewed, AssignedToUser, Favourites, WorkedOn } = state.data.tabs;

			const tabs = {
				RecentlyViewed: {
					data: transformRecentlyViewedResult(RecentlyViewed, intl),
					timeZone: RecentlyViewed?.timeZone,
					update: generateUpdateTabFn('RecentlyViewed', recentlyViewedFetchData),
				},

				AssignedToUser: {
					data: transformAssignedToUserResult(AssignedToUser),
					update: generateUpdateTabFn('AssignedToUser', assignedToUserFetchData),
				},

				Favourites: {
					data: transformFavouritesResult(Favourites),
					update: generateUpdateTabFn('Favourites', favouritesFetchData),
				},
				WorkedOn: {
					data: transformRecentActivitiesResult(WorkedOn, intl),
				},
			};

			return {
				loading: state.loading,
				refresh: state.refresh,
				promise: state.promise,
				error: state.error,
				data: {
					lastVisitedTab: (state.data && state.data.lastVisitedTab) || '',
					tabs,
					updateLastVisitedTab,
				},
			};
		}
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		log.safeErrorWithoutCustomerData('spa-apps.jira-home', 'Cannot prefetch the data', e);
	}

	return {
		loading: state.loading,
		refresh: state.refresh,
		promise: state.promise,
		error: state.error,
		data: null,
	};
});

export const JiraHomeShell = () => {
	const { atlassianAccountId } = useTenantContext();
	const intl = useIntl();
	const state = useJiraHomePrefetchResource(intl);

	if (!atlassianAccountId) {
		return <LoginRedirect />;
	}

	return (
		<UFOSegment name="your-work">
			<AppBase id="jira-home">
				<AppStyle>
					{/* @ts-expect-error - TS2322 - Type '{ loading: boolean; promise: Promise<JiraHomeRouteResourceData | null> | null; error: Error | Record<string, any> | (Error & Record<...>) | (Record<...> & Error) | null; data: { ...; }; } | { ...; }' is not assignable to type 'Partial<RouteResourceResponse<PrefetchResult>>'. */}
					<JiraHome prefetchResult={state} />
				</AppStyle>
			</AppBase>
		</UFOSegment>
	);
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<>
		<MarkProductStart />
		<OnboardingCheck>
			<JiraHomeShell />
		</OnboardingCheck>
	</>
);
