import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	emptyStateHeader: {
		defaultMessage: 'Find all your open issues in one place',
		id: 'home.tabs.assigned-to-me.empty-state-header',
		description: 'Empty State header for jira home starred tab',
	},
	emptyStateDescription: {
		defaultMessage: 'You have no open issues assigned to you',
		id: 'home.tabs.assigned-to-me.empty-state-description',
		description: 'Empty State description for jira home assigned tab',
	},
	seeAllIssuesAssignedToMe: {
		defaultMessage: 'View all',
		id: 'home.tabs.assigned-to-me.see-all-issues-assigned-to-me',
		description:
			'The label for the button that links the user to the issue search page page which shows them all of the issues that are assigned to them.',
	},
});
