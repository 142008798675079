import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	errorHeader: {
		defaultMessage: 'Something went wrong',
		id: 'home.common.error-state.error-header',
		description: 'Header for error state in jira home.',
	},
	errorDescription: {
		defaultMessage: 'We couldn’t load your work, try refreshing the page.',
		id: 'home.common.error-state.error-description',
		description: 'Description for error state in jira home.',
	},
});
