import React, { type ReactNode, type SyntheticEvent } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button, { type Appearance } from '@atlaskit/button';
import Link from '@atlaskit/link';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { Link as OldLink } from '@atlassian/jira-router';

type Props = {
	children: ReactNode;
	actionSubjectId: string;
};

type ButtonSpacing = 'compact' | 'default' | 'none';

const BaseAnalyticsComponent = (
	props: Props & {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		component?: any;
		href?: string;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		onClick?: (e: SyntheticEvent<any>, analyticsEvent: UIAnalyticsEvent) => void;
		appearance?: Appearance;
		spacing?: ButtonSpacing;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		extraAttributes?: any;
		buttonLink?: boolean;
	},
) => {
	const { children, actionSubjectId, extraAttributes, buttonLink, ...rest } = props;

	return (
		<Button
			{...rest}
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			onClick={(e: SyntheticEvent<any>, analyticsEvent: UIAnalyticsEvent) => {
				if (extraAttributes) {
					fireUIAnalytics(analyticsEvent, actionSubjectId, extraAttributes);
				} else {
					fireUIAnalytics(analyticsEvent, actionSubjectId);
				}

				if (props.onClick) {
					props.onClick(e, analyticsEvent);
				}
			}}
		>
			{children}
		</Button>
	);
};

export const ButtonWithAnalytics = (
	props: {
		appearance?: Appearance;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		onClick: (e: SyntheticEvent<any>, analyticsEvent: UIAnalyticsEvent) => void;
		spacing?: ButtonSpacing;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		extraAttributes?: any;
	} & Props,
) => <BaseAnalyticsComponent {...props} />;

ButtonWithAnalytics.displayName = 'ButtonWithAnalytics';

export const LinkWithAnalytics = (
	props: {
		href: string;
		appearance?: Appearance;
		spacing?: ButtonSpacing;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		extraAttributes?: any;
		buttonLink?: boolean; // this is added so that it doesnt affect current behaviours in other tabs in Your work
	} & Props,
) => (
	<BaseAnalyticsComponent
		{...props}
		component={
			// eslint-disable-next-line no-nested-ternary
			props.buttonLink
				? props.appearance === 'link' && props.href // We only apply the new link component for anchor tag with "link" appearance
					? Link
					: OldLink
				: undefined
		}
	/>
);

LinkWithAnalytics.displayName = 'LinkWithAnalytics';
