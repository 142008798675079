import { defineMessages } from '@atlassian/jira-intl';

export const types = defineMessages({
	business: {
		id: 'deep-dive-cards-section.common.business',
		defaultMessage: 'business',
		description: '',
	},
	// eslint-disable-next-line jira/i18n/id-named-by-package
	service_desk: {
		id: 'deep-dive-cards-section.common.service-project',
		defaultMessage: 'service',
		description: '',
	},
	software: {
		id: 'deep-dive-cards-section.common.software',
		defaultMessage: 'software',
		description: '',
	},
	// eslint-disable-next-line jira/i18n/id-named-by-package
	product_discovery: {
		id: 'deep-dive-cards-section.common.product_discovery',
		defaultMessage: 'product discovery',
		description: '',
	},
});

export const general = defineMessages({
	queue: {
		id: 'deep-dive-cards-section.common.queue',
		defaultMessage: '{numberBoards} {numberBoards, plural, one {queue} other {queues}}',
		description: '',
	},
	board: {
		id: 'deep-dive-cards-section.common.board',
		defaultMessage: '{numberBoards} {numberBoards, plural, one {board} other {boards}}',
		description: '',
	},
	views: {
		id: 'deep-dive-cards-section.common.views',
		defaultMessage: '{numberBoards} {numberBoards, plural, one {view} other {views}}',
		description: '',
	},
});

export const boards = defineMessages({
	companyManagedBusiness: {
		id: 'deep-dive-cards-section.common.company-managed-business',
		defaultMessage: 'Company-managed business',
		description: 'Company-managed business boards title.',
	},
	teamManagedBusiness: {
		id: 'deep-dive-cards-section.common.team-managed-business',
		defaultMessage: 'Team-managed business',
		description: 'Team-managed business boards title',
	},
	companyManagedServiceManagement: {
		id: 'deep-dive-cards-section.common.company-managed-service-management',
		defaultMessage: 'Service management',
		description: 'Service management boards title.',
	},
	teamManagedServiceManagement: {
		id: 'deep-dive-cards-section.common.team-managed-service-management',
		defaultMessage: 'Team-managed service management',
		description: 'Team-managed service management boards title.',
	},
	companyManagedSoftware: {
		id: 'deep-dive-cards-section.common.company-managed-software',
		defaultMessage: 'Company-managed software',
		description: 'Company-managed software boards title.',
	},
	teamManagedSoftware: {
		id: 'deep-dive-cards-section.common.team-managed-software',
		defaultMessage: 'Team-managed software',
		description: 'Team managed software boards title.',
	},
	productDiscovery: {
		id: 'deep-dive-cards-section.common.product-discovery',
		defaultMessage: 'Product Discovery',
		description: 'Product discovery software boards title.',
	},
});

export const state = defineMessages({
	error: {
		id: 'deep-dive-cards-section.common.error',
		defaultMessage: 'Failed to retrieve project data',
		description: 'Message displayed when deep dive card subdata cannot be retrieved.',
	},
	errorCount: {
		id: 'deep-dive-cards-section.common.error-count',
		defaultMessage: "Couldn't retrieve count",
		description: 'Failed to retrieve the number of items message displayed in a tooltip.',
	},
});

export const heading = defineMessages({
	queuesHeading: {
		id: 'deep-dive-cards-section.common.queues-heading',
		defaultMessage: 'Recent queues',
		description: 'Heading for queue links.',
	},
	boardsHeading: {
		id: 'deep-dive-cards-section.common.boards-heading',
		defaultMessage: 'Quick links',
		description: 'Heading for boards links.',
	},
});
