// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { Fragment } from 'react';
import PageHeader from '@atlassian/jira-common-components-page-header';
import { ff } from '@atlassian/jira-feature-flagging';
import { useIntl } from '@atlassian/jira-intl';
import { IssueCreateHomePageTrigger } from '@atlassian/jira-issue-create-home-page-trigger';
import { useTenantContext, useIsSiteAdmin } from '@atlassian/jira-tenant-context-controller';
import { AsyncBasicAuthPostDeprecationUsersBanner } from './basic-auth-post-deprecation-banner/async';
import messages from './messages';

const Header = () => {
	const { formatMessage } = useIntl();
	const { atlassianAccountId: accountId } = useTenantContext();

	const isSiteAdmin = useIsSiteAdmin();
	if (!accountId) {
		return null;
	}

	return (
		<>
			{ff('basic-auth-error-banner-in-your-work-page_96hg5') && isSiteAdmin && (
				<AsyncBasicAuthPostDeprecationUsersBanner />
			)}
			<IssueCreateHomePageTrigger />
			<PageHeader truncateTitle>{formatMessage(messages.title)}</PageHeader>
		</>
	);
};

export default Header;
