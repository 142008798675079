import {
	WORKEDON_TAB_KEY,
	VIEWED_TAB_KEY,
	ASSIGNED_TAB_KEY,
	STARRED_TAB_KEY,
} from '../common/constants/tab-content';
import type { TabConfig } from '../common/types/tabs';
import { AssignedToMeTab, WorkedOnTab, RecentlyViewedTab, StarredTab } from './tabs';

// Provider must match the key of a service provider
// The id's are used for analytics tracking, as well as a machine name
// by the tabs navigation component.
// If you change them, you will break analytics.
// You can change what the user sees in the translation.
export const Tabs: TabConfig[] = [
	{
		id: WORKEDON_TAB_KEY,
		provider: 'WorkedOn',
		showCount: false,
		tabContent: WorkedOnTab,
	},
	{
		id: VIEWED_TAB_KEY,
		provider: 'RecentlyViewed',
		showCount: false,
		tabContent: RecentlyViewedTab,
	},
	{
		id: ASSIGNED_TAB_KEY,
		provider: 'AssignedToUser',
		showCount: true,
		tabContent: AssignedToMeTab,
	},
	{
		id: STARRED_TAB_KEY,
		provider: 'Favourites',
		showCount: false,
		tabContent: StarredTab,
	},
].filter(Boolean);
