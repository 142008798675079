import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	board: {
		defaultMessage: 'Board',
		id: 'home.common.item-list.item.board',
		description: 'Page title for the Jira Home page.',
	},
	filter: {
		defaultMessage: 'Filter',
		id: 'home.common.item-list.item.filter',
		description:
			'The label for the Jira Home tab which contains the list of issues the user recently viewed.',
	},
	dashboard: {
		defaultMessage: 'Dashboard',
		id: 'home.common.item-list.item.dashboard',
		description:
			'The label for the Jira Home tab which contains the list of issues assigned to the user.',
	},
	project: {
		defaultMessage: 'Project',
		id: 'home.common.item-list.item.project',
		description:
			'The label for the Jira Home tab which contains the list of issues assigned to the user.',
	},
	queue: {
		defaultMessage: 'Queue',
		id: 'home.common.item-list.item.queue',
		description:
			'The label for the Jira Home tab which contains the list of issues assigned to the user.',
	},
	plan: {
		defaultMessage: 'Plan',
		id: 'home.common.item-list.item.plan',
		description:
			'The label for the Jira Home tab which contains the list of issues assigned to the user.',
	},
	companyManagedBusiness: {
		id: 'home.common.item-list.item.company-managed-business',
		defaultMessage: 'Company-managed business',
		description:
			'The project subtitle label for the Jira Home tab which contains the list of issues the user recently viewed.',
	},
	teamManagedBusiness: {
		id: 'home.common.item-list.item.team-managed-business',
		defaultMessage: 'Team-managed business',
		description:
			'The project subtitle label for the Jira Home tab which contains the list of issues the user recently viewed.',
	},
	companyManagedServiceManagement: {
		id: 'home.common.item-list.item.company-managed-service-management',
		defaultMessage: 'Service management',
		description: 'Service management Project Label',
	},
	teamManagedServiceManagement: {
		id: 'home.common.item-list.item.team-managed-service-management',
		defaultMessage: 'Team-managed service management',
		description:
			'The project subtitle label for the Jira Home tab which contains the list of issues the user recently viewed.',
	},
	companyManagedSoftware: {
		id: 'home.common.item-list.item.company-managed-software',
		defaultMessage: 'Company-managed software',
		description:
			'The project subtitle label for the Jira Home tab which contains the list of issues the user recently viewed.',
	},
	teamManagedSoftware: {
		id: 'home.common.item-list.item.team-managed-software',
		defaultMessage: 'Team-managed software',
		description:
			'The project subtitle label for the Jira Home tab which contains the list of issues the user recently viewed.',
	},
	productDiscovery: {
		id: 'home.common.item-list.item.product-discovery',
		defaultMessage: 'Product discovery',
		description:
			'The project subtitle label for the Jira Home tab which contains the list of issues the user recently viewed.',
	},
});
