import get from 'lodash/get';
import some from 'lodash/some';
import values from 'lodash/values';
import type { FavoriteChangeContextType, Item, ItemMap, Items } from '../../model/types';

/**
 * Finds if the item has been changed pessimistically.
 *
 * @param {*} item an item in the latest favorite change context
 * @param {*} prevItemsByType the items in the previous favorite change context, grouped by type
 */
const isItemChangedPessimistically = (item: Item, prevItemsByType: Items) => {
	const { type, id, value, pending } = item;

	if (pending === true) {
		return false;
	}

	const prevItem = get(prevItemsByType, `${type}.${id}`);
	if (!prevItem) {
		return true;
	}

	return prevItem.pending || prevItem.value !== value;
};

/**
 * Finds if any items in the array have a non-pending change.
 *
 * @param {*} items the items from latest favorite change context
 * @param {*} prevItemsByType the items in the previous favorite change context, grouped by type
 */
const itemsHavePessimisticUpdate = (items: Item[], prevItemsByType: Items) =>
	some(items, (item: Item) => isItemChangedPessimistically(item, prevItemsByType));

/**
 * Finds if any items in the change context have a non-pending change.
 *
 * @param {*} prevContext the previous favorite change context
 * @param {*} nextContext the new favorite change context
 */
export const favoritesChangedPessimistically = (
	prevContext: FavoriteChangeContextType,
	nextContext: FavoriteChangeContextType,
): boolean => {
	const prevItemsByType = prevContext.items;
	const nextItemsByType = nextContext.items;

	// @ts-expect-error - TS2769 - No overload matches this call.
	return some(nextItemsByType, (itemsById: ItemMap) =>
		itemsHavePessimisticUpdate(values(itemsById), prevItemsByType),
	);
};
