/**
 * A signalling mechanism for Cypress tests.
 *
 * This acts as a bridge between the storybook application and cypress for
 * checking behaviours which cannot be asserted on via the DOM or are
 * otherwise not reflected in application state.
 *
 * This is the 'emit' side of the story. It should be used by
 * production-facing code to emit a signal.
 */

const isProduction = (): boolean => process.env.NODE_ENV === 'production';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const registerSignal = (signal: string, meta: any) => {
	window.cySignals.allSignals.push(signal);

	const signalsByName = window.cySignals.signalsByName;
	if (signalsByName[signal]) {
		signalsByName[signal].push(meta);
	} else {
		signalsByName[signal] = [meta];
	}
};

const initCySignals = () => {
	window.cySignals = {
		signalsByName: {},
		allSignals: [],
	};
};

/**
 * Emit a signal, along with any metadata about it.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const emitSignal = (signal: string, meta: any = {}): void => {
	if (isProduction()) {
		return;
	}
	if (!window.cySignals) {
		initCySignals();
	}
	registerSignal(signal, meta);
};
