import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl';
import { PRACTICE_LABEL_MAP, practiceKeyJqlNameMapping } from './constants';

// Returns null for no-practice / no-category / unrecognized practices
export const getPracticeName = (itsmPracticeKey: string) =>
	// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ readonly "change-management": "Changes"; readonly "incident-management": "Incidents"; readonly "problem-management": "Problems"; readonly "service-request": "Service requests"; readonly "post-incident-review": "Post-incident reviews"; }'.
	practiceKeyJqlNameMapping[itsmPracticeKey] || null;

export const getPracticeLabel = (itsmPracticeKey: string): MessageDescriptor | undefined =>
	// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ readonly "change-management": MessageDescriptorV2; readonly "service-request": MessageDescriptorV2; readonly "problem-management": MessageDescriptorV2; readonly "incident-management": MessageDescriptorV2; readonly "post-incident-review": MessageDescriptorV2; readonly "no-category": MessageDescriptorV2; }'.
	PRACTICE_LABEL_MAP[itsmPracticeKey];
