import React from 'react';
import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages';

type Props = {
	displayedCount: number;
	totalCount: number;
};

const ItemCounter = (props: Props) => {
	const { formatMessage } = useIntl();

	return props.displayedCount && props.totalCount ? (
		<ItemCounterWrapper {...props}>
			{formatMessage(messages.showingCounter, {
				numberOfDisplayedItems: props.displayedCount,
				totalNumberOfItems: props.totalCount > 99 ? '99+' : props.totalCount,
			})}
		</ItemCounterWrapper>
	) : null;
};

export default ItemCounter;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ItemCounterWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', colors.N200),
});
