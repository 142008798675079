import React, { useEffect, type ReactNode } from 'react';
import Placeholder from '@atlassian/jira-placeholder';
import { useGetQueryById } from '../store';

// @ts-expect-error - TS7031 - Binding element 'id' implicitly has an 'any' type.
const PlaceholderFallback = ({ id }) => {
	const [, { setQueryLoading }] = useGetQueryById(id);

	useEffect(() => {
		setQueryLoading(id);
	}, [id, setQueryLoading]);

	return null;
};

export const Suspense = ({ id, children }: { id: string; children: ReactNode }) => (
	<Placeholder name="query-renderer" fallback={<PlaceholderFallback id={id} />}>
		{children}
	</Placeholder>
);
