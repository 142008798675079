import { type Project, MAX_CARDS } from '@atlassian/jira-deep-dive-cards-section';
import type { RouteResourceResponse } from '@atlassian/jira-router';
import type { Item } from '../../common/types/item';
import type { PrefetchResult } from '../../common/types/item-provider';

const getRecentlyViewedItemsByType = (
	prefetchResult?: RouteResourceResponse<PrefetchResult>,
	// @ts-expect-error - TS1016 - A required parameter cannot follow an optional parameter.
	type: string,
): Item[] => {
	if (
		prefetchResult &&
		prefetchResult.data &&
		prefetchResult.data.tabs.RecentlyViewed &&
		prefetchResult.data.tabs.RecentlyViewed.data
	) {
		const recentlyViewedSections = prefetchResult.data.tabs.RecentlyViewed.data.sections;
		// TODO: flatten
		let recentItemInfo: Array<Item> = [];
		for (let i = 0; i < recentlyViewedSections.length; i += 1) {
			recentItemInfo = recentItemInfo.concat(
				recentlyViewedSections[i] ? recentlyViewedSections[i].items : [],
			);
		}
		return recentItemInfo.filter((item) => item.type === type) || [];
	}
	return [];
};

const getTop2RecentQueues = (
	prefetchResult?: RouteResourceResponse<PrefetchResult>,
	// @ts-expect-error - TS1016 - A required parameter cannot follow an optional parameter.
	containerId: string,
): Item[] => {
	const recentQueuesInfo = getRecentlyViewedItemsByType(prefetchResult, 'queues')
		.filter((item) =>
			item.attributes && item.attributes.projectId !== undefined
				? item.attributes.projectId === containerId
				: false,
		)
		.slice(0, 2);
	return recentQueuesInfo || [];
};

const getProjectType = (subtitle?: string) => {
	switch (subtitle) {
		case 'Service Desk':
		case 'Service':
			return 'service_desk';
		case 'Business':
			return 'business';
		case 'Product Discovery':
			return 'product_discovery';
		default:
			return 'software';
	}
};

export const getRecentProjects = (
	prefetchResult?: RouteResourceResponse<PrefetchResult>,
): Project[] => {
	if (typeof prefetchResult === 'undefined') {
		return [];
	}
	const top5recentProjects: Item[] = getRecentlyViewedItemsByType(prefetchResult, 'projects').slice(
		0,
		MAX_CARDS,
	);
	return top5recentProjects.map<Project>((project: Item) => {
		const projectUrlSplit = project.url.split('/');
		const projectKey = projectUrlSplit[projectUrlSplit.length - 1];
		return {
			projectName: project.title,
			projectType: getProjectType(project.subtitle),
			avatarUrl: project.avatarUrl != null ? project.avatarUrl : '',
			href: project.url,
			isSimplified:
				project.attributes && project.attributes.nextgen
					? project.attributes.nextgen === 'true'
					: false,
			// @ts-expect-error - TS2345 - Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
			id: parseInt(project.entityId, 10),
			key: projectKey,
		};
	});
};

export const getRecent2QueuesByRecentProjects = (
	prefetchResult?: RouteResourceResponse<PrefetchResult>,
	// @ts-expect-error - TS1016 - A required parameter cannot follow an optional parameter.
	recentProjects: Project[],
) => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const queuesByProjectId: Record<string, any> = {};
	recentProjects
		.filter((project) => project.projectType === 'service_desk')
		.map((project) => {
			queuesByProjectId[`project_${project.id}`] = getTop2RecentQueues(
				prefetchResult,
				project.id.toString(),
			).map((queue) => ({
				id: queue.id,
				title: queue.title,
				href: queue.url,
			}));
			return null;
		});
	return queuesByProjectId;
};
