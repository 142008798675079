/** @jsx jsx */
import React from 'react';
import { jsx } from '@compiled/react';
import { cssMap } from '@atlaskit/css';
import CheckIcon from '@atlaskit/icon/glyph/check';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { ff } from '@atlassian/jira-feature-flagging';
import { useIntl } from '@atlassian/jira-intl';
import { MAX_ITEMS_PER_PAGE } from '../../../../common/constants/tab-content';
import { ButtonWithAnalytics, LinkWithAnalytics } from '../../../../common/ui/with-analytics';
import messages from './messages';

const styles = cssMap({
	iconWrapper: {
		display: 'inline-block',
		position: 'relative',
	},
	tabFooter: {
		textAlign: 'center',
		marginTop: token('space.400', '32px'),
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: '32px',
	},
});

export type Props = {
	maxItems: number;
	totalItemCount: number;
	onLoadMoreClick?: () => void;
};

const LinkToGlobalIssueNavigation = ({ children }: React.PropsWithChildren<{}>) => (
	<LinkWithAnalytics
		appearance="link"
		href="/issues/?jql=order+by+created+DESC"
		actionSubjectId="globalIssueNavigationButton"
		buttonLink={ff('nin.global-scope_aqec8') ? true : undefined}
	>
		{children}
	</LinkWithAnalytics>
);
export const RecentlyViewedTabFooter = (props: Props) => {
	const { maxItems, onLoadMoreClick, totalItemCount } = props;

	const { formatMessage } = useIntl();

	const allItemsLoadedText = formatMessage(messages.allItemsLoadedViewAllIssues, {
		a: (chunks: React.ReactNode[]) => (
			<Box paddingInline="space.100" xcss={fontStyle}>
				<LinkToGlobalIssueNavigation>{chunks}</LinkToGlobalIssueNavigation>
			</Box>
		),
	});

	if (totalItemCount <= MAX_ITEMS_PER_PAGE) {
		return <div css={styles.tabFooter}>{allItemsLoadedText}</div>;
	}
	if (totalItemCount > maxItems && onLoadMoreClick) {
		return (
			<div css={styles.tabFooter}>
				<ButtonWithAnalytics
					actionSubjectId="recentlyViewedLoadMoreButton"
					onClick={onLoadMoreClick}
				>
					{formatMessage(messages.loadMore)}
				</ButtonWithAnalytics>
			</div>
		);
	}
	return (
		<div css={styles.tabFooter}>
			<div css={styles.iconWrapper}>
				<CheckIcon size="medium" label="" />
			</div>
			{allItemsLoadedText}
		</div>
	);
};

RecentlyViewedTabFooter.defaultProps = {
	onLoadMoreClick: undefined,
};

export default RecentlyViewedTabFooter;
const fontStyle = xcss({ fontWeight: token('font.weight.medium', '500') });
