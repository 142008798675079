import { useEffect } from 'react';

// Hook is necessary because errors should be fired outside of renders
// @ts-expect-error - TS1016 - A required parameter cannot follow an optional parameter.
export const useEffectFireErrorCallback = (error?: boolean, callback: () => void) => {
	useEffect(() => {
		if (error === true) {
			callback();
		}
	}, [callback, error]);
};
