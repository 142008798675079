import React from 'react';
import { styled } from '@compiled/react';
import Icon from '@atlaskit/icon/glyph/warning';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

const ErrorIcon = () => (
	<BackgroundContainer>
		<Icon size="small" label="" primaryColor={token('color.icon.danger', colors.R400)} />
	</BackgroundContainer>
);

export default ErrorIcon;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BackgroundContainer = styled.span({
	lineHeight: 1,
});
