import { UFOExperience, ExperienceTypes, ExperiencePerformanceTypes } from '@atlassian/ufo';

export const yourWorkFirstStepExperience = new UFOExperience(
	'jsm-first-session-flow-your-work-tour-first-step',
	{
		type: ExperienceTypes.Load,
		performanceType: ExperiencePerformanceTypes.PageSegmentLoad,
	},
);

export const projectTourFirstStepExperience = new UFOExperience(
	'jsm-first-session-flow-project-tour-first-step',
	{
		type: ExperienceTypes.Load,
		performanceType: ExperiencePerformanceTypes.PageSegmentLoad,
	},
);
