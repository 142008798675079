import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	viewAll: {
		id: 'deep-dive-cards-section.view-all',
		defaultMessage: 'View all projects',
		description:
			'The text for the link for users to go to the project directory page to view all projects.',
	},
});
