import { performGetRequest } from '@atlassian/jira-fetch';
import type { ProjectId, BaseUrl } from '@atlassian/jira-shared-types';

export const isDemoProject = async ({
	baseUrl,
	projectId,
}: {
	baseUrl: BaseUrl;
	projectId: ProjectId;
}) => {
	try {
		const response = await performGetRequest(
			`${baseUrl}/rest/api/3/project/${projectId}/properties/isDemo`,
		);
		return !!response && !!response.value;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		if (error.statusCode === 404) {
			/**
			 * Expected when project property is not found.
			 * Demo project has project property "isDemo = true",
			 * otherwise property does not exist
			 * and response status will be 404
			 */
			return false;
		}

		throw error;
	}
};

export const getItsmSampleProject = async ({
	baseUrl,
}: {
	baseUrl: BaseUrl;
}): Promise<
	| {
			projectId: number;
			projectKey: string;
	  }
	| undefined
> => {
	try {
		const response = await performGetRequest(
			`${baseUrl}/rest/servicedesk/onboarding/1/itsm/sample-project`,
		);
		return response;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		if (error.statusCode === 404) {
			return undefined;
		}

		throw error;
	}
};
