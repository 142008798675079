import React, { createContext, useContext } from 'react';
import {
	CORE_PROJECT,
	CUSTOMER_SERVICE_PROJECT,
	PRODUCT_DISCOVERY_PROJECT,
	SERVICE_DESK_PROJECT,
	SOFTWARE_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl';
import type { DeepDiveProviderProps, SectionProps } from '../../common/types';
import { messages } from './messages';
import {
	getCoreProjectDefaultBoard,
	useFetchAssignedIssuesCountPerProject,
	useFetchProductDiscoveryViews,
	useFetchProjectColor,
	useFetchQueues,
	useFetchSoftwareBoards,
} from './utils';

const DeepDiveCardContext = createContext<SectionProps>({
	isloadingProjects: true,
	baseUrl: '',
	sectionTitle: '',
	data: [],
});

export const DeepDiveCardProvider = ({
	recentProjects,
	recent2QueuesByRecentProjects,
	children,
	isLoading,
}: DeepDiveProviderProps) => {
	const baseUrl = '';
	const intl = useIntl();
	const softwareAndCoreFilters = useFetchAssignedIssuesCountPerProject(recentProjects, [
		PRODUCT_DISCOVERY_PROJECT,
		SOFTWARE_PROJECT,
		CORE_PROJECT,
	]);
	const softwareBoards = useFetchSoftwareBoards(recentProjects);
	const productDiscoveryViews = useFetchProductDiscoveryViews(recentProjects);
	const serviceDeskBoardsAndFilters = useFetchQueues(recentProjects, recent2QueuesByRecentProjects);
	const projectColors = useFetchProjectColor(recentProjects);

	// Build props per supported project type
	const supportedProjects = recentProjects.filter(({ projectType }) =>
		[
			PRODUCT_DISCOVERY_PROJECT,
			SOFTWARE_PROJECT,
			CORE_PROJECT,
			SERVICE_DESK_PROJECT,
			CUSTOMER_SERVICE_PROJECT,
		].includes(projectType),
	);

	const data = supportedProjects.map((projectBase) => {
		const project = { ...projectBase, barColor: projectColors[projectBase.key] };
		if (project.projectType === SOFTWARE_PROJECT || project.projectType === CORE_PROJECT) {
			return {
				project,
				boards:
					project.projectType === SOFTWARE_PROJECT
						? {
								loading:
									softwareBoards.data &&
									softwareBoards.data[`${project.id}`] &&
									softwareBoards.data[`${project.id}`].loading,
								error:
									softwareBoards.data &&
									softwareBoards.data[`${project.id}`] &&
									softwareBoards.data[`${project.id}`].error,
								data: softwareBoards.data && softwareBoards.data[`${project.id}`],
							}
						: {
								data: getCoreProjectDefaultBoard(project, baseUrl, intl),
							},
				filters: {
					loading: softwareAndCoreFilters.loading,
					error: softwareAndCoreFilters.error,
					data: softwareAndCoreFilters.data && softwareAndCoreFilters.data[project.key],
				},
			};
		}
		if (project.projectType === PRODUCT_DISCOVERY_PROJECT) {
			return {
				project,
				boards: {
					loading:
						productDiscoveryViews.data &&
						productDiscoveryViews.data[`${project.id}`] &&
						productDiscoveryViews.data[`${project.id}`].loading,
					error:
						productDiscoveryViews.data &&
						productDiscoveryViews.data[`${project.id}`] &&
						productDiscoveryViews.data[`${project.id}`].error,
					data: productDiscoveryViews.data && productDiscoveryViews.data[`${project.id}`],
				},
				filters: {
					loading: softwareAndCoreFilters.loading,
					error: softwareAndCoreFilters.error,
					data: softwareAndCoreFilters.data && softwareAndCoreFilters.data[project.key],
				},
			};
		}
		if (
			project.projectType === SERVICE_DESK_PROJECT ||
			project.projectType === CUSTOMER_SERVICE_PROJECT
		) {
			return {
				project,
				boards: {
					loading:
						serviceDeskBoardsAndFilters &&
						serviceDeskBoardsAndFilters[project.key] &&
						serviceDeskBoardsAndFilters[project.key].loading,
					error:
						serviceDeskBoardsAndFilters &&
						serviceDeskBoardsAndFilters[project.key] &&
						serviceDeskBoardsAndFilters[project.key].error,
					data:
						serviceDeskBoardsAndFilters &&
						serviceDeskBoardsAndFilters[project.key] &&
						serviceDeskBoardsAndFilters[project.key].boards,
				},
				filters: {
					loading:
						serviceDeskBoardsAndFilters &&
						serviceDeskBoardsAndFilters[project.key] &&
						serviceDeskBoardsAndFilters[project.key].loading,
					error:
						serviceDeskBoardsAndFilters &&
						serviceDeskBoardsAndFilters[project.key] &&
						serviceDeskBoardsAndFilters[project.key].error,
					data:
						serviceDeskBoardsAndFilters &&
						serviceDeskBoardsAndFilters[project.key] &&
						serviceDeskBoardsAndFilters[project.key].filters,
				},
			};
		}
		throw new Error('Unreachable.');
	});

	return (
		<DeepDiveCardContext.Provider
			value={{
				isloadingProjects: isLoading,
				sectionTitle: intl.formatMessage(messages.sectionTitle),
				baseUrl,
				// @ts-expect-error - TS2322 - Type '{ project: { barColor: string; projectName: string; projectType: ProjectType; avatarUrl: string; href: string; id: number; key: string; isSimplified: boolean; }; boards: { loading: boolean | undefined; error: boolean | undefined; data: DeepDiveItem | undefined; } | { ...; }; filters: { ...; }; }[]' is not assignable to type 'Props[]'.
				data,
			}}
		>
			{children}
		</DeepDiveCardContext.Provider>
	);
};

export const useDeepDiveCardContext = () => useContext(DeepDiveCardContext);
