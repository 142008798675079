import { colors } from '@atlaskit/theme';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';

// TODO: Use tokens for colors
export const notDraggingMixin = `
    z-index: 100;
    box-shadow: 0 1px 1px ${colors.N50A}, 0 0 1px ${colors.N60A};
`;

export const draggingMixin = `
    z-index: 300;
    box-shadow: 0 ${gridSize}px ${gridSize * 2}px ${-gridSize / 2}px ${colors.N50A}, 0 0 1px ${
			colors.N60A
		};
`;
