import type { Services } from '../common/types/services';
import AssignedToUserProvider from '../services/assigned-to-user-provider';
import FavouritesProvider from '../services/favourites-provider';
import RecentActivityWorkedOnProvider from '../services/recent-activity-provider';
import RecentlyViewedProvider from '../services/recently-viewed-provider';

export type InjectedServices = {
	services: Services;
};

export const services: Services = {
	WorkedOn: RecentActivityWorkedOnProvider,
	RecentlyViewed: RecentlyViewedProvider,
	AssignedToUser: AssignedToUserProvider,
	Favourites: FavouritesProvider,
};
