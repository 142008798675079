import {
	fetchFirstProjectAttributes,
	type FetchFirstProjectAttributes,
} from './fetch-first-project-attributes';

export const actions = {
	fetchFirstProjectAttributes,
} as const;

export type StoreActions = typeof actions;

export type Actions = {
	fetchFirstProjectAttributes: FetchFirstProjectAttributes;
};
