import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	emptyStateHeader: {
		defaultMessage: 'You haven’t viewed any item recently',
		id: 'home.tabs.recently-viewed.empty-state-header',
		description: 'Empty State header for jira home viewed tab',
	},
	emptyStateDescription: {
		defaultMessage:
			'In this page, you’ll find a list of your recently viewed items. Get started by finding the project your team is working on.',
		id: 'home.tabs.recently-viewed.empty-state-description',
		description: 'Empty State description for jira home viewed tab',
	},
	viewAllProjects: {
		defaultMessage: 'View all projects',
		id: 'home.tabs.recently-viewed.view-all-projects',
		description:
			'The message show in the button on the empty state of the Recently Viewed tab on Jira Home.',
	},
	loadMore: {
		defaultMessage: 'Load more',
		id: 'home.tabs.recently-viewed.load-more',
		description: 'A label for a button that will load more issues when clicked',
	},
});
