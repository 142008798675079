import React from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import EmptyState from '@atlaskit/empty-state';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { LinkWithAnalytics } from '../../common/ui/link';
import messages from './messages';

type Props = {
	projectsDirectoryUrl: string;
	actionSubjectId: string;
};

export const EmptyStateDeepDiveCard = ({ projectsDirectoryUrl, actionSubjectId }: Props) => {
	const { formatMessage } = useIntl();

	return (
		<EmptyState
			description={formatMessage(messages.emptyStateDescription)}
			header=""
			primaryAction={
				<Button appearance="primary">
					<EmptyStateLink href={projectsDirectoryUrl} actionSubjectId={actionSubjectId}>
						{formatMessage(messages.viewAllProjects)}
					</EmptyStateLink>
				</Button>
			}
		/>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EmptyStateLink = styled(LinkWithAnalytics)({
	color: token('color.text.inverse', 'white'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	':hover, :active': {
		color: 'inherit',
		textDecoration: 'none',
	},
});
