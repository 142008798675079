import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	emptyStateDescription: {
		id: 'deep-dive-cards-section.empty-state.empty-state-description',
		defaultMessage: 'You have no recently viewed projects',
		description:
			'The message indicates users have no recently viewed projects in the dep dive card',
	},
	viewAllProjects: {
		id: 'deep-dive-cards-section.empty-state.view-all-projects',
		defaultMessage: 'View all projects',
		description: 'The message on the button to view all projects',
	},
});
