import { Component } from 'react';

/* eslint-disable jira/react/handler-naming */
type Props = {
	mount: () => unknown;
	unmount: () => unknown;
};

// eslint-disable-next-line jira/react/no-class-components
class OpTrigger extends Component<Props> {
	componentDidMount() {
		this.props.mount();
	}

	componentWillUnmount() {
		this.props.unmount();
	}

	render() {
		return null;
	}
}

export default OpTrigger;
