export const PERF_METRICS_TABS_PREFIX = 'jira-home.tabs';
export const WORKEDON_TAB_KEY = 'workedon';
export const VIEWED_TAB_KEY = 'viewed';
export const ASSIGNED_TAB_KEY = 'assigned';
export const STARRED_TAB_KEY = 'starred';

export type TabsTypes =
	| typeof WORKEDON_TAB_KEY
	| typeof VIEWED_TAB_KEY
	| typeof ASSIGNED_TAB_KEY
	| typeof STARRED_TAB_KEY;

export const MAX_ITEMS_PER_PAGE = 30;

export const PRODUCTS = ['jira'];

export const SUCCESS_STATE = 'success' as const;
export const ERROR_STATE = 'error' as const;
export const EMPTY_STATE = 'empty' as const;
