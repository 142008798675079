/* eslint-disable jira/i18n/id-named-by-package */
import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	loadMoreButton: {
		defaultMessage: 'Load new activity',
		id: 'home.tab-container.load-more-button',
		description: 'Text of a button on the your work page which loads the latest data',
	},
});
