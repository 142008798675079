import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	loadMore: {
		defaultMessage: 'Load more',
		id: 'home.tabs.recently-viewed.footer.load-more',
		description: 'A label for a button that will load more issues when clicked',
	},
	allItemsLoaded: {
		defaultMessage: 'Looking for something else? {trySearch}',
		id: 'home.tabs.recently-viewed.footer.all-items-loaded',
		description: 'The message shown when all items have been loaded.',
	},
	trySearch: {
		defaultMessage: 'Try search',
		id: 'home.tabs.recently-viewed.footer.try-search',
		description:
			'A link that takes users to the issue search (global issue navigator) when clicked',
	},
	allItemsLoadedViewAllIssues: {
		defaultMessage: 'Couldn’t find your issue? <a>View all issues</a>',
		id: 'home.tabs.recently-viewed.footer.all-items-loaded-view-all-issues',
		description:
			'The message shown when all items have been loaded - renamed from Looking for something else? Try search.',
	},
});
