import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	header: {
		defaultMessage: 'Star your work for quick access',
		id: 'home.tabs.starred.empty-state.header',
		description: 'Empty State header for jira home starred tab',
	},
	description: {
		defaultMessage: 'Get started by finding your',
		id: 'home.tabs.starred.empty-state.description',
		description: 'Empty State description for jira home starred tab',
	},
	project: {
		defaultMessage: 'Project',
		id: 'home.tabs.starred.empty-state.project',
		description: 'button label to go to Projects',
	},
	board: {
		defaultMessage: 'Board',
		id: 'home.tabs.starred.empty-state.board',
		description: 'button label to go to Boards',
	},
	serviceProject: {
		id: 'home.tabs.starred.empty-state.service-project',
		defaultMessage: 'Service project',
		description: 'button label to go to service project',
	},
	filter: {
		defaultMessage: 'Filter',
		id: 'home.tabs.starred.empty-state.filter',
		description: 'button label to go to Filters',
	},
	dashboard: {
		defaultMessage: 'Dashboard',
		id: 'home.tabs.starred.empty-state.dashboard',
		description: 'button label to go to Dashboards',
	},
	plan: {
		defaultMessage: 'Plan',
		id: 'home.tabs.starred.empty-state.plan',
		description: 'button label to go to Plans',
	},
});
