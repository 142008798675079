import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	emptyStateHeader: {
		defaultMessage: 'You haven’t worked on anything yet',
		id: 'home.tabs.worked-on.empty-state-header',
		description: 'Empty State header for jira home worked on tab',
	},
	emptyStateDescription: {
		defaultMessage:
			'In this page, you’ll find your recently worked on issues. Get started by finding the project your team is working on.',
		id: 'home.tabs.worked-on.empty-state-description',
		description: 'Empty State description for jira home worked on tab',
	},
	viewAllProjects: {
		defaultMessage: 'View all projects',
		id: 'home.tabs.worked-on.view-all-projects',
		description:
			'The message show in the button on the empty state of the Recently Viewed tab on Jira Home.',
	},
	loadMore: {
		defaultMessage: 'Load more',
		id: 'home.tabs.worked-on.load-more',
		description: 'A label for a button that will load more issues when clicked',
	},
});
