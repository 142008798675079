import React from 'react';
import { styled } from '@compiled/react';
import AKEmptyState, { type EmptyStateProps } from '@atlaskit/empty-state';
import { token } from '@atlaskit/tokens';

const emptyStateMaxImageWidth = 300;
const emptyStateMaxImageHeight = 250;

const EmptyState = (props: EmptyStateProps) => (
	<EmptyWrapper data-test-id="global-pages.home.common.ui.empty-state">
		<AKEmptyState
			maxImageWidth={emptyStateMaxImageWidth}
			maxImageHeight={emptyStateMaxImageHeight}
			{...props}
		/>
	</EmptyWrapper>
);

EmptyState.defaultProps = {
	primaryAction: undefined,
	secondaryAction: undefined,
};

export default EmptyState;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EmptyWrapper = styled.div({
	marginTop: token('space.1000', '80px'),
	width: '100%',
});
