import React from 'react';
import OnMountAndUnmount from '@atlassian/jira-common-util-op-triggers/src/on-mount-and-unmount';
import { type UFOExperience, UFOExperienceState } from '@atlassian/ufo';

/**
 * This component is used to start experiences only once. This is useful in onboarding cases where
 * you may want to track a full experience including load time, however after loading, the onboarding
 * may not be allowed to show yet. This component ensures only full experiences will be tracked, and
 * excludes any that would be triggered by SPA transitions.
 *
 * @param experiences an array of experiences that should be started when this component is mounted
 */
export const StartExperiencesOnce = ({
	experiences,
}: {
	experiences: readonly UFOExperience[];
}) => (
	<OnMountAndUnmount
		mount={() => {
			// Only start the experiences if they haven't been started before
			experiences
				.filter((exp) => exp.state.id === UFOExperienceState.NOT_STARTED.id)
				.forEach((exp) => exp.start());
		}}
		unmount={() => {
			// Abort the experiences on unmount
			experiences.forEach((exp) => exp.abort());
		}}
	/>
);
