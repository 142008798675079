import { metrics } from '@atlassian/browser-metrics';

export const jiraHomePageLoad = metrics.pageLoad({
	key: 'jira-home',
	ssr: {
		doneAsFmp: true,
	},
	histogram: {
		initial: {
			fmp: '2500_3000_3500_4000_5000_6000',
			tti: '2500_4000_4500_5000_6000_7000_8000_9000',
		},
		transition: {
			fmp: '1000_2500_3000_3500_4000_5000_6000',
			tti: '1000_2500_3000_3500_4000_5000_6000',
		},
	},
	featureFlags: ['opsgenie-consolidation-transitioning-state_d7vm9'],
});
