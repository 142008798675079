import React, { Component } from 'react';
import { parseISO, subSeconds } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { fetchJson } from '@atlassian/jira-fetch';
import { injectIntl, type IntlShape } from '@atlassian/jira-intl';
import { type TimeZone, toTimeZone } from '@atlassian/jira-shared-types';
import { MAX_ITEMS_PER_PAGE } from '../../common/constants/tab-content';
import messages from '../../common/messages';
import type { Item } from '../../common/types/item';
import type { InjectedItemProviderProps, ItemProviderData } from '../../common/types/item-provider';
import type { FetchRecentlyViewedData, RecentlyViewedData } from '../../common/types/viewed';
import { addSection, getSortedItems } from '../../common/utils';
import ItemProvider from '../item-provider';

export const queryUrl = '/rest/internal/2/productsearch/singleRecentList';

export const timeZoneUrl = '/rest/api/3/myself';

export const transformResult = (
	result?: RecentlyViewedData,
	// @ts-expect-error - TS1016 - A required parameter cannot follow an optional parameter.
	intl: IntlShape,
): ItemProviderData | undefined => {
	if (result == null) {
		return undefined;
	}

	const { data: items, timeZone } = result;

	if (items == null || items.length === 0) {
		return { sections: [], total: 0 };
	}

	const currentTimeInTimeZone = utcToZonedTime(parseISO(new Date().toISOString()), timeZone);

	const getDateForComparison = (item: Item) =>
		typeof item.viewTimeAgo === 'number'
			? subSeconds(currentTimeInTimeZone, item.viewTimeAgo / 1000)
			: undefined;

	const sortedItems = getSortedItems(items, timeZone, intl, getDateForComparison);

	const todayItems = sortedItems.todayItems.map((item: Item) => ({
		...item,
		entityId: item.id,
		favourite: undefined,
	}));
	const yesterdayItems = sortedItems.yesterdayItems.map((item: Item) => ({
		...item,
		entityId: item.id,
		favourite: undefined,
	}));
	const thisWeekItems = sortedItems.thisWeekItems.map((item: Item) => ({
		...item,
		entityId: item.id,
		favourite: undefined,
	}));
	const thisMonthItems = sortedItems.thisMonthItems.map((item: Item) => ({
		...item,
		entityId: item.id,
		favourite: undefined,
	}));
	const moreThanAMonthItems = sortedItems.moreThanAMonthItems.map((item: Item) => ({
		...item,
		entityId: item.id,
		favourite: undefined,
	}));

	// @ts-expect-error - TS2304 - Cannot find name 'ItemProviderDataSection'.
	const sections: Array<ItemProviderDataSection> = [];
	addSection(sections, todayItems, intl.formatMessage(messages.today));
	addSection(sections, yesterdayItems, intl.formatMessage(messages.yesterday));
	addSection(sections, thisWeekItems, intl.formatMessage(messages.inTheLastWeek));
	addSection(sections, thisMonthItems, intl.formatMessage(messages.inTheLastMonth));
	addSection(sections, moreThanAMonthItems, intl.formatMessage(messages.moreThanAMonthAgo));

	return { sections, total: items.length };
};

export const fetchRecentlyViewedItems = async (): Promise<Item[]> => {
	const result = await fetchJson(queryUrl, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			groups: [
				{
					types: ['issues', 'boards', 'projects', 'filters', 'dashboards', 'queues'],
					limit: MAX_ITEMS_PER_PAGE * 2,
				},
			],
		}),
	});

	if (
		typeof result !== 'undefined' &&
		Array.isArray(result) &&
		typeof result[0] !== 'undefined' &&
		typeof result[0] !== 'undefined' &&
		Array.isArray(result[0].items)
	) {
		return result[0].items;
	}
	throw Error('Could not retrieve recently viewed');
};

const timeZoneUTC = toTimeZone('UTC');

export const fetchTimeZone = async (): Promise<TimeZone> => {
	const result = await fetch(timeZoneUrl);

	if (result.status > 200) {
		return timeZoneUTC;
	}

	const userData = await result.json();

	if (userData && userData.timeZone) {
		return toTimeZone(userData.timeZone);
	}

	return timeZoneUTC;
};

export const fetchAllData: FetchRecentlyViewedData = async () => {
	const [rawData, timeZone] = await Promise.all([fetchRecentlyViewedItems(), fetchTimeZone()]);

	return {
		data: rawData,
		timeZone,
	};
};

type InternalProps = InjectedItemProviderProps & {
	intl: IntlShape;
};

type State = {
	data: TimeZone | undefined;
	loading: boolean;
	error: Error | undefined;
};

// eslint-disable-next-line jira/react/no-class-components
class RecentlyViewedProvider extends Component<InternalProps, State> {
	// eslint-disable-next-line react/sort-comp
	getTimeZoneProps(): TimeZone | undefined {
		const { prefetchResult, userProviderData } = this.props;

		if (
			userProviderData.loading === false &&
			typeof userProviderData.error === 'undefined' &&
			// @ts-expect-error - Property 'data' does not exist on type 'never'.
			userProviderData.data !== null &&
			// @ts-expect-error - Property 'data' does not exist on type 'never'.
			userProviderData.data.user &&
			// @ts-expect-error - TS2339 - Property 'timeZone' does not exist on type 'DataBasic | (DataBasic & ResponseSuccess)'.
			userProviderData.data.user.timeZone
		) {
			// @ts-expect-error - TS2339 - Property 'timeZone' does not exist on type 'DataBasic | (DataBasic & ResponseSuccess)'.
			return userProviderData.data.user.timeZone;
		}

		if (
			prefetchResult.data &&
			prefetchResult.data.tabs &&
			prefetchResult.data.tabs[this.props.providerKey]
		) {
			const data = prefetchResult.data.tabs[this.props.providerKey];
			// @ts-expect-error - TS2532 - Object is possibly 'undefined'.
			if (typeof data.timeZone !== 'undefined') {
				// @ts-expect-error - TS2532 - Object is possibly 'undefined'.
				return toTimeZone(data.timeZone);
			}
		}
		return undefined;
	}

	state = {
		data: this.getTimeZoneProps(),
		loading: !this.getTimeZoneProps(),
		error: undefined,
	};

	componentDidMount() {
		this.canSetState = true;
		if (!this.getTimeZoneProps()) {
			this.fetchTimeZone();
		}
	}

	componentWillUnmount() {
		this.canSetState = false;
	}

	fetching = false;

	canSetState = false;

	fetchTimeZone = async () => {
		if (this.fetching === true) {
			return;
		}

		try {
			this.fetching = true;

			const data = await fetchTimeZone();
			if (!this.canSetState) {
				return;
			}

			if (typeof data === 'undefined') {
				return;
			}

			this.setState((prevState: State) => ({
				...prevState,
				loading: false,
				data,
				error: undefined,
			}));
			this.fetching = false;
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			if (this.canSetState) {
				this.setState({ loading: false, error: e });
			}
			this.fetching = false;
		}
	};

	render() {
		const { intl, userProviderData, ...otherProps } = this.props;

		const { data: timeZone } = this.state;

		if (typeof timeZone === 'undefined') {
			return this.props.children({
				loading: this.state.loading,
				reloading: false,
				data: { sections: [], total: 0 },
				error: this.state.error,
			});
		}

		return <ItemProvider {...otherProps} />;
	}
}

export default injectIntl(RecentlyViewedProvider);
