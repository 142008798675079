export const BOARDS_ITEM_TYPE = 'board' as const;
export const FILTERS_ITEM_TYPE = 'filter' as const;
export const DASHBOARDS_ITEM_TYPE = 'dashboard' as const;
export const ISSUES_ITEM_TYPE = 'issue' as const;
export const COMMENT_ITEM_TYPE = 'comment' as const;
export const PROJECTS_ITEM_TYPE = 'project' as const;
export const QUEUES_ITEM_TYPE = 'queue' as const;
export const PLANS_ITEM_TYPE = 'plan' as const;

// ItemType is used in ui/item-list/item-icon to determine which icon to render, and by ui/item-list to
// determine which fields to render
export type ItemType =
	| typeof BOARDS_ITEM_TYPE
	| typeof FILTERS_ITEM_TYPE
	| typeof DASHBOARDS_ITEM_TYPE
	| typeof ISSUES_ITEM_TYPE
	| typeof PROJECTS_ITEM_TYPE
	| typeof QUEUES_ITEM_TYPE
	| typeof PLANS_ITEM_TYPE;
