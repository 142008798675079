import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	showingCounter: {
		defaultMessage: 'Showing {numberOfDisplayedItems} of {totalNumberOfItems}',
		id: 'home.common.item-counter.showing-counter',
		description:
			'Text to show to the user how many list items are visible out of a given total amount',
	},
});
